<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown id="state" v-model="caso" :options="casos" optionLabel="nombre" placeholder="Selecciona..." @change="Buscar()"></Dropdown>
					</template>
					
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
					</template>
				
				</Toolbar>
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Personal: {{products.length}}</h5>
							<Button label="Imprimir" icon="pi pi-file-pdf" class="p-button-rounded p-button-info p-mr-2" @click="VERPDF(products)"/>
							<Button label="Exportar" icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VERXLS()"/>

							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="empresa" header="Empresa" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.empresa}}<br>
							<strong :style="'color:'+slotProps.data.color">{{slotProps.data.cargo}}</strong><br>
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column field="cedula" header="Nº Cédula" :sortable="true">
						<template #body="slotProps">
							<img style="width: 75px;" :src="url.upload+slotProps.data.foto" v-if="slotProps.data.foto"/><br>
							{{slotProps.data.nac}}-{{new Intl.NumberFormat("de-DE").format(slotProps.data.cedula)}}
							<i class="bi bi-person-badge" v-if="slotProps.data.doc_cedula"></i>
							<i class="bi bi-shield-check" v-if="slotProps.data.doc_vacuna"></i><br>
							Fecha.Nac:{{convfech(slotProps.data.fechanac)}} <br>
							{{slotProps.data.edad}} años
						</template>
					</Column>
					<Column field="nombre" header="Nombres" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre+' '+slotProps.data.nombre2}}
						</template>
					</Column>
					<Column field="apellido" header="Apellidos" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido+' '+slotProps.data.apellido2}}
						</template>
					</Column>
					<Column field="correo" header="Correo y Celular" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.correo}}<br>
							{{slotProps.data.telefono}}
						</template>
					</Column>
					<Column field="antig_year" header="Tiempo de Servicio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.antig_year}} años {{slotProps.data.antig_month}} meses {{slotProps.data.antig_day}} días
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit"/>
							<Button icon="bi bi-file-earmark-person" class="p-button-rounded p-button-primary p-mr-2" @click="VerCarnet(slotProps.data)"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Personal" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-8">
								<img style="width: 200px;" :src="url.upload+product.foto" v-if="product.foto"/>
								<FileUpload chooseLabel="Subir Foto JPG" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(img)" :auto="true" v-if="product.id>0"/>
							</div>
							<div class="p-col-4" v-if="product.nivel">
								<qr-code 
									:text="'https://grupovictoriavzla.com/#/validacion/'+product.cedula"
									size="200"
									color="#000000"
								></qr-code>
							</div>
							<div class="p-col-4" v-else>
								<qr-code 
									:text="'https://grupovictoriavzla.com/#/validacion/'+product.cedula"
									size="200"
									color="#000000"
								></qr-code>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
								<label class="p-mb-3">Nac</label>
								<Dropdown id="state" v-model="product.nac" :options="nacionalidades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label for="name">N° Cédula</label>
								<InputNumber id="cedula" v-model="product.cedula" integeronly required="true" min="0" disable="true" autofocus/>
							</div>
							<div class="p-col-2">
								<label for="name">Primer Apellido</label>
								<InputText id="name" v-model.trim="product.apellido" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<label for="name">Segundo Apellido</label>
								<InputText id="name" v-model.trim="product.apellido2" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<label for="name">Primer Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<label for="name">Segundo Nombre</label>
								<InputText id="name" v-model.trim="product.nombre2" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<label for="fecha">Fecha de Nac. ({{product.edad}} años)</label>
                                <InputText v-model="product.fechanac" type="date"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Teléfono</label>
                                <InputMask v-model="product.telefono" mask="+99(999)9999999" />
							</div>
							<div class="p-col-4">
								<label for="name">Correo</label>
								<InputText id="name" v-model.trim="product.correo" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<label for="fecha">Fecha de Ingreso</label>
                                <InputText v-model="product.fechaing" type="date"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Fecha de Egreso</label>
                                <InputText v-model="product.fechaegre" type="date" :disabled="product.activo"/>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Empresa</label>
								<Dropdown id="state" v-model="product.empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Nivel</label>
								<Dropdown id="state" v-model="product.nivel" :options="niveles" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Condición</label>
								<Dropdown id="state" v-model="product.condicion" :options="condiciones" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-3">Ubicación</label>
								<Dropdown id="state" v-model="product.sede" :options="sedes" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-3">Unidad</label>
								<Dropdown id="state" v-model="product.unidad" :options="unidades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-6">
								<label for="name">Cargo</label>
								<InputText id="direccion" v-model="product.cargo" required="true"/>
							</div>
							<div class="p-col-3">
								<label class="p-mb-3">Estado</label>
								<Dropdown id="state" v-model="product.estado" :options="estados" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-5">
								<label for="name">Dirección</label>
								<InputText id="direccion" v-model="product.direccion" required="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Est. Civil</label>
								<Dropdown id="state" v-model="product.estcivil" :options="estcivils" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label for="name">Hijos</label>
								<InputText id="direccion" v-model="product.hijos" required="true"/>
							</div>
							<div class="p-col-3">
								<label class="p-mb-3">Banco</label>
								<Dropdown id="state" v-model="product.banco" :options="bancos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label for="name">Nº de Cuenta</label>
								<InputText id="direccion" v-model="product.cuenta" required="true"/>
							</div>
							<div class="p-col-2">
								<label for="price">Sueldo</label>
								<InputNumber id="price" v-model="product.sueldo" mode="currency" currency="USD" locale="en-US" />
							</div>
							<div class="p-col-2">
								<label for="price">Bono</label>
								<InputNumber id="price" v-model="product.bono" mode="currency" currency="USD" locale="en-US" />
							</div>
							<div class="p-col-2">
								<label for="price">Comisión</label>
								<InputNumber id="price" v-model="product.comision" locale="en-US" />
							</div>
							<div class="p-col-1">
								<h6 style="margin-top: 0">En Nómina</h6>
								<InputSwitch v-model="product.nomina" />
							</div>
							<div class="p-col-1">
								<h6 style="margin-top: 0">Estatus</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-col-4">
								<label>Copia Cédula</label>
								<FileUpload chooseLabel="Subir JPG" uploadLabel="Cargar" mode="basic" :name="img2" :url="url.api+'upload.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader2(img2)" :auto="true"/>
								<img style="max-width: 250px;" :src="url.upload+product.doc_cedula" v-if="product.doc_cedula"/>
							</div>
							<div class="p-col-4">
								<label>Vacunación</label>
								<FileUpload chooseLabel="Subir JPG" uploadLabel="Cargar" mode="basic" :name="img3" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="1000000" @upload="myUploader3(img3)" :auto="true"/>
								<img style="max-width: 250px;" :src="url.upload+product.doc_vacuna" v-if="product.doc_vacuna"/>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import PDF from "../service/PDF";
	import Token from "uuid-token-generator";
	import url from "../service/_URL";
	import XLS from "../service/XLS";

	export default {
		data() {
			return {
				nuevo: false,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				empresas: [],
                estados: [],
                bancos: [],
                niveles: [],
                unidades: [],
                condiciones: [],
                sedes: [],
                nacionalidades: [
                    {nombre: 'Venezolano(a)', code: 'V'},
                    {nombre: 'Extranjero(a)', code: 'E'}
                ],
                casos: [
                    {nombre: 'Personal Activo', code: 0},
                    {nombre: 'Personal Activo en Nómina', code: 1},
                    {nombre: 'Personal Activo fuera de Nómina', code: 2},
                    {nombre: 'Personal Desincorporado', code: 3}
                ],
                estcivils: [],
				caso: null,
				img: null,
				img2: null,
				img3: null,
			}
		},
		productService: null,
		created() {
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				this.empresas = [];
                this.estados = [];
                this.bancos = [];
                this.niveles = [];
                this.unidades = [];
				const Consulta = new API('RRHH');
				Consulta.Ini('Personal').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.estados = result.consult.estados;
					this.empresas = result.consult.empresas;
					this.estcivils = result.consult.estcivils;
					this.bancos = result.consult.bancos;
					this.niveles = result.consult.niveles;
					this.unidades = result.consult.unidades;
					this.condiciones = result.consult.condiciones;
					this.products = result.consult.personal;
					this.sedes = result.consult.sedes;
					this.caso = this.casos[0];
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				this.$store.commit('Loading');
				this.products = [];
				const Consulta = new API('RRHH');
				Consulta.Procesar('Personal',{
					caso: 	'Buscar',
					tipo: this.caso.code,
				}).then(response => {
					//this.$store.state.error = response.result[0];
					this.products = response.result;
					this.$store.commit('Loading');
				});
			},
			VERPDF(data){
				const doc = new PDF();
				doc.Personal(this.caso,data);
			},
			VERXLS(){
				const xls = new XLS();
				xls.Personal(this.caso,this.products);
			},
			VerCarnet(data){
				window.open(url.api+'Carnet.php?&id='+data.cedula, '_blank');
			},

			openNew() {
				this.nuevo = true;
				const tokgen = new Token(256);
				this.img = tokgen.generate();
				this.img2 = tokgen.generate();
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.nac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Nacionalidad', life: 5000});
				} else if (this.product.cedula == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Nº de Cédula', life: 5000});
				} else if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.apellido == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Apellido', life: 5000});
				} else if (this.product.fechanac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Fecha de Nacimiento', life: 5000});
				} else if (this.product.telefono == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Teléfono', life: 5000});
				} else if (this.product.fechaing == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Fecha de Ingreso', life: 5000});
				} else if (this.product.correo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Correo', life: 5000});
				} else if (this.product.fechaegre == null && !this.product.activo) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Fecha de Egreso', life: 5000});
				} else if (this.product.estcivil == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Estado Civil', life: 5000});
				} else if (this.product.banco == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Banco', life: 5000});
				} else {
					this.$store.commit('Loading');
					this.buscar = this.product.id;		
					const Consulta = new API('RRHH');
					if (this.nuevo) {
						Consulta.Procesar('Personal',{
							caso: 	'INS',
							personal: this.product,
						}).then(response => {
							this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.productDialog = false;
								this.product = {};
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
							this.$store.commit('Loading');
						});
					} else {
						Consulta.Procesar('Personal',{
							caso: 	'UPD',
							personal: this.product,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.productDialog = false;
								this.product = {};
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
							this.$store.commit('Loading');
						});
					}
					
				}
			},
			editProduct(product) {
				const tokgen = new Token(256);
				this.img = tokgen.generate();
				this.img2 = tokgen.generate();
				this.img3 = tokgen.generate();
				this.nuevo = false;
				this.product = {...product};
				this.estcivils.forEach(element => {
					if(this.product.estcivil==element.code){
						this.product.estcivil = element;
					}
				});  
				this.empresas.forEach(element => {
					if(this.product.empresa_id==element.code){
						this.product.empresa = element;
					}
				});  
				this.estados.forEach(element => {
					if(this.product.estado_id==element.code){
						this.product.estado = element;
					}
				});  
				this.nacionalidades.forEach(element => {
					if(this.product.nac==element.code){
						this.product.nac = element;
					}
				});  
				this.bancos.forEach(element => {
					if(this.product.banco==element.code){
						this.product.banco = element;
					}
				});  
				this.niveles.forEach(element => {
					if(this.product.nivel_id==element.code){
						this.product.nivel = element;
					}
				});  
				this.unidades.forEach(element => {
					if(this.product.unidad_id==element.code){
						this.product.unidad = element;
					}
				});  
				this.condiciones.forEach(element => {
					if(this.product.condicion_id==element.code){
						this.product.condicion = element;
					}
				});  
				this.sedes.forEach(element => {
					if(this.product.sede_id==element.code){
						this.product.sede = element;
					}
				});  
				this.productDialog = true;
			},
			myUploader(img) {
				this.$store.commit('Loading');
				const Consulta = new API('RRHH');
				Consulta.Procesar('Personal',{
					caso: 	'RevJPG',
					foto: img,
				}).then(response => {
					//this.$store.state.error =response;
					if(response.result){
						this.product.foto = response.result;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			myUploader2(img) {
				this.$store.commit('Loading');
				const Consulta = new API('RRHH');
				Consulta.Procesar('Personal',{
					caso: 	'RevJPG',
					foto: img,
				}).then(response => {
					//this.$store.state.error =response;
					if(response.result){
						this.product.doc_cedula = response.result;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			myUploader3(img) {
				this.$store.commit('Loading');
				const Consulta = new API('RRHH');
				Consulta.Procesar('Personal',{
					caso: 	'RevJPG',
					foto: img,
				}).then(response => {
					//this.$store.state.error =response;
					if(response.result){
						this.product.doc_vacuna = response.result;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
                const Consulta = new API('RRHH');          
				Consulta.Procesar('Personal',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
