import { createRouter, createWebHashHistory } from 'vue-router';
import Home from './pages/Home.vue';
import Validacion from './pages/Validacion.vue';

//Paginas
import Clientes from './pages/Clientes.vue';
import Procesos from './pages/Procesos.vue';
import DocLegales from './pages/DocLegales.vue';
import DocTecnicos from './pages/DocTecnicos.vue';
import Viaticos from './pages/Viaticos.vue';
import RevViaticos from './pages/RevViaticos.vue';
import RecViaticos from './pages/RecViaticos.vue';
import Catalogo from './pages/Catalogo.vue';
import Personal from './pages/Personal.vue';
import Cumples from './pages/Cumples.vue';

//Configuración
import Usuarios from './pages/Usuarios.vue';
import Roles from './pages/Roles.vue';
import Parametros from './pages/Parametros.vue';

const routes = [
    {
        path: '/',
        name: 'Inicio',
        component: Home,
    },
    {
        path: "/validacion/:cedula",
        name: 'Validacion',
        component: Validacion,
    },
    {
        path: "/usuarios",
        name: 'Usuarios',
        component: Usuarios,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/roles",
        name: 'Roles',
        component: Roles,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/clientes",
        name: 'Clientes',
        component: Clientes,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/procesos",
        name: 'Procesos',
        component: Procesos,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/procesos",
        name: 'Procesos',
        component: Procesos,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/doclegales",
        name: 'DocLegales',
        component: DocLegales,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/doctecnicos",
        name: 'DocTecnicos',
        component: DocTecnicos,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/viaticos",
        name: 'Viaticos',
        component: Viaticos,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/revviaticos",
        name: 'RevViaticos',
        component: RevViaticos,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/recviaticos",
        name: 'RecViaticos',
        component: RecViaticos,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/catalogo",
        name: 'Catalogo',
        component: Catalogo,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/personal",
        name: 'Personal',
        component: Personal,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/cumples",
        name: 'Cumples',
        component: Cumples,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/parametros",
        name: 'Parametros',
        component: Parametros,
        meta: {
            authRequired: true
        }
    },



];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
