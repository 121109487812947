<template>
	<div class="p-grid">
		<div class="p-grid p-justify-center">
			<!--<img style="width: 40%;" alt="logo" src="logo.png">-->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		methods: {
		},
			components: {
			}

	}
</script>

<style scoped lang="scss">
	p {
		line-height: 1.5;
		margin: 0;
	}

	::v-deep(.p-card .p-card-title) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;
	}
</style>
