let URL = {

  produccion: {
    doc: "./api/",
    api: "./api/",
    upload: "./api/upload/",
    correo: null,
    clave: null
  },

  local: {
    doc: "./api/",
    api: "http://localhost/vue-project/GrupoVictoria/src/api/",
    upload: "http://localhost/vue-project/GrupoVictoria/src/api/upload/",
    correo: "wilmer78@gmail.com",
    clave: "@Paola13288051"
  },

  prueba: {
    doc: "./api/",
    api: "https://grupovictoriavzla.com/api/",
    upload: "https://grupovictoriavzla.com/api/upload/",
    correo: "wilmer78@gmail.com",
    clave: "@Paola13288051"
  }

};

export default URL['prueba'];