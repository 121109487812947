import jsPDF from 'jspdf';
import 'jspdf-autotable';

var pdf = new jsPDF();

class PDFs {

    Plantilla(empresa){
        pdf.addImage("images/empresas/"+empresa.id+".jpg", 'JPEG', 20, 10, 30, 25);

        pdf.setFontSize(8);
        pdf.setFont("italic", "bold");
        pdf.text(100, 281, empresa.nombre, 'center');
        pdf.setFont("italic","normal");
        pdf.text(100, 284, 'Las Acacias - Caracas', 'center');
        pdf.text(100, 287, 'Tlf '+empresa.tlflocal, 'center');
        pdf.text(100, 290, 'Correo: '+empresa.correo, 'center');
    }
    
    Tabla(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [114, 115, 118],valign: 'middle', halign : 'center'}, 
            columnStyles: {text: {columnWidth: 'auto'}},             
            styles: {
                //font: 'courier',
                lineColor: [114, 115, 118],
                lineWidth: 0.1
            },
        });
    }

    Out(nombre){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf.output("blob"), nombre+".pdf");
        } else {
            //pdf.autoPrint();
            window.open(
                URL.createObjectURL(pdf.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {    
                window.URL.revokeObjectURL(pdf.output("bloburl"));
            }, 100);
        }
        pdf = new jsPDF();      
    }

    moneda(number){
        return new Intl.NumberFormat("de-DE").format(parseFloat(number).toFixed(2));
    }

    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }

    Personal(titulo,data) {
        pdf = new jsPDF('l', 'mm', 'a3');
        pdf.addImage('logo.png', 'png', 10, 5, 20, 20);

        pdf.addImage('images/logos/1.jpg', 'JPEG', 160, 10, 15, 15);
        pdf.addImage('images/logos/3.jpg', 'JPEG', 180, 10, 15, 15);
        pdf.addImage('images/logos/4.jpg', 'JPEG', 200, 10, 15, 15);
        pdf.addImage('images/logos/5.jpg', 'JPEG', 220, 10, 15, 15);
        pdf.addImage('images/logos/6.jpg', 'JPEG', 240, 10, 15, 15);
        pdf.addImage('images/logos/7.jpg', 'JPEG', 260, 10, 15, 15);
        pdf.addImage('images/logos/8.jpg', 'JPEG', 280, 10, 15, 15);
        pdf.addImage('images/logos/9.jpg', 'JPEG', 300, 10, 15, 15);
        pdf.addImage('images/logos/10.jpg', 'JPEG', 320, 10, 15, 15);
        pdf.addImage('images/logos/11.jpg', 'JPEG', 340, 10, 15, 15);
        pdf.addImage('images/logos/12.jpg', 'JPEG', 360, 10, 15, 15);
        pdf.addImage('images/logos/13.jpg', 'JPEG', 380, 10, 15, 15);

        pdf.setFont("italic", "bold");
        pdf.setFontSize(16);
        //pdf.setFont("italic","normal");

        pdf.text(100, 20, 'Lista de '+titulo.nombre, 'center');

        pdf.setFontSize(10);
        pdf.setFont("italic","normal");

        var head = [['Nº','Empresa','Cédula','Apellidos','Nombres','Fecha Nacimiento','Celular','Correo','Fecha Ingreso','Cargo','Condición','Sueldo','Bono','Comisión']];
        if(titulo.code==3){
            head = [['Nº','Empresa','Cédula','Apellidos','Nombres','Fecha Nacimiento','Celular','Correo','Fecha Ingreso','Fecha Egreso','Cargo','Sueldo','Bono','Comisión']];
        }
        var body = [];
        var totalbono = 0;
        var totalsueldo = 0;
        var egreso = null;
        for (var i = 0; i < data.length; i++) {
            totalsueldo = parseFloat(totalsueldo) + parseFloat(data[i].sueldo);
            totalbono = parseFloat(totalbono) + parseFloat(data[i].bono);
            if(data[i].activo){
                egreso = 'Activo';
            } else {
                egreso = this.convfech(data[i].fechaegre);
            }
            if(titulo.code==3){
                body.push([
                    i+1,
                    data[i].empresa,
                    data[i].nac+'-'+new Intl.NumberFormat("de-DE").format(data[i].cedula),
                    data[i].apellido+' '+data[i].apellido2,
                    data[i].nombre+' '+data[i].nombre2,
                    this.convfech(data[i].fechanac),
                    data[i].telefono,
                    data[i].correo,
                    this.convfech(data[i].fechaing),
                    egreso,
                    data[i].cargo,
                    data[i].sueldo+'$',
                    data[i].bono+'$',
                    data[i].comision+'%',
                ]);

            } else {
                body.push([
                    i+1,
                    data[i].empresa,
                    data[i].nac+'-'+new Intl.NumberFormat("de-DE").format(data[i].cedula),
                    data[i].apellido+' '+data[i].apellido2,
                    data[i].nombre+' '+data[i].nombre2,
                    this.convfech(data[i].fechanac),
                    data[i].telefono,
                    data[i].correo,
                    this.convfech(data[i].fechaing),
                    data[i].cargo,
                    data[i].condicion,
                    data[i].sueldo+'$',
                    data[i].bono+'$',
                    data[i].comision+'%',
                ]);
            }   
        }
        if(titulo.code != 3){
            body.push([
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'TOTAL',
                totalsueldo+'$',
                totalbono+'$',
                '',
            ]);

        }
        this.Tabla({top: 10, right: 10, bottom: 40, left: 10},30,head,body);  
        
        
        // FOOTER
        //var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        //var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();



        const pages = pdf.internal.getNumberOfPages();
        const pageWidth = pdf.internal.pageSize.width;  //Optional
        const pageHeight = pdf.internal.pageSize.height;  //Optional
        pdf.setFontSize(10);  //Optional

        /*        
        pdf.setTextColor(100);
        pdf.setFontSize(10);
        pdf.text(str, pageWidth / 2, pageHeight  - 11, 'center');
        */
        let str1 = "Lic. Laura Evies";
        let str2 = "Gerente de Talento Humano";

        for (let j = 1; j < pages + 1 ; j++) {
              let horizontalPos = pageWidth / 2;  //Can be fixed number
              let verticalPos = pageHeight - 10;  //Can be fixed number
              pdf.setPage(j);
              pdf.text(str1, horizontalPos, verticalPos, 'center');
              pdf.text(str2, horizontalPos, verticalPos+5, 'center');
              //pdf.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
              pdf.text(10, verticalPos, `${j} de ${pages}`);

        }
        
        this.Out('Personal');  
        pdf = new jsPDF();      
    }





}


export default PDFs;



//export default exports;