<template>
<div class="p-d-flex p-jc-center" style="margin-top: -50px;">
	<div class="p-grid ">
		<div class="p-col-9 p-offset-1">
			<Card v-if="datos">
				<template #title>
					<img style="width: 90px;" :src="url.upload+datos.foto" v-if="datos.foto"/><br>
					<img style="width: 90px;" :src="url.api+'/logos/'+datos.empresa_id+'.jpg'"/><br>
				</template>
				<template #content>
					<h4><strong>{{datos.nombre+' '+datos.apellido}}</strong></h4>
					<h4>
						<strong>
							{{new Intl.NumberFormat("de-DE").format(datos.cedula)}}
						</strong>
					</h4>
					<br><strong> Cargo: {{datos.cargo}}</strong>
				</template>
				<template #footer style="width: 200px;">
					Información de validación y acreditación del personal adscrito a la empresa. En caso de extravío y pérdida, Favor Comunicarse a los telefónos: 0281-514.36.78 / 0212-634.46.64/74.60
				</template>
			</Card>
			<h4 v-if="error">
				<strong>{{error}}</strong>
			</h4>		
		</div>
	</div>
</div>


</template>

<script>
	import API from "../service/ApiConsulta";
	import url from "../service/_URL";

	export default {
		data() {
			return {
				cedula: null,
				url: null,
				datos: null,
				error: null
			}
		},
		created(){
			this.$store.commit('Loading');
			this.datos = null;
			this.error = null;
			this.url = url;
			this.cedula = this.$route.params.cedula;
			const Consulta = new API('Validacion');
			Consulta.Procesar('Carnet',{
				cedula: this.cedula,
			}).then(response => {
				//this.$store.state.error =response;
				if(response.result){
					this.datos = response.result;
				} else {
					this.error =  'Cédula No Registrada'
				}
				this.$store.commit('Loading');
			});
		}
	}
</script>

<style scoped lang="scss">
	p {
		line-height: 1.5;
		margin: 0;
	}

	::v-deep(.p-card .p-card-title) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;
	}
</style>
