<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown id="state" v-model="empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..." @change="Buscar"></Dropdown>
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert && empresa"/>
					</template>			
				</Toolbar>
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Documentos Legales</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="tipo" header="Tipo" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.tipo}}
						</template>
					</Column>
					<Column field="fecha" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{convfech(slotProps.data.fecha)}}
						</template>
					</Column>
					<Column field="vencimiento" header="Vencimiento" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.vencimiento}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data.id)" v-if="slotProps.data.img"/>
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Documento Legal" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<label class="p-mb-3">Empresa: </label>
								<strong>{{empresa.nombre}}</strong>
							</div>
							<div class="p-col-5">
								<label class="p-mb-3">Tipo de Documento</label>
								<Dropdown id="state" v-model="product.tipo" :options="tipos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label for="fecha">Fecha</label>
                                <InputText v-model="product.fecha" type="date"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Vencimiento</label>
								<Dropdown id="state" v-model="product.vencimiento" :options="vencimientos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Estatus</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-col-2">
								<FileUpload chooseLabel="Subir PDF" uploadLabel="Cargar" mode="basic" :name="'docleg'+product.id" :url="url.api+'upload.php'" accept=".pdf" :maxFileSize="1000000" @upload="myUploader" :auto="true" v-if="product.id"/>
							</div>
							<div class="p-col-12" v-if="product.img">
								<embed :src="url.upload+'docleg'+product.id+'.pdf'" type="application/pdf" width="100%" height="600px" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import url from "../service/_URL";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				codes: [
					{code: 'V', nombre: 'V'},
					{code: 'J', nombre: 'J'},
					{code: 'G', nombre: 'G'}
				],
                empresas: [],
                tipos: [],
                vencimientos: [],
                empresa: null,
			}
		},
		productService: null,
		created() {
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				const Consulta = new API('Documentos');
				Consulta.Ini('Legales').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.tipos = result.consult.tipos;
					this.vencimientos = result.consult.vencimientos;
					this.empresas = result.consult.empresas;
					this.$store.commit('Loading');
				}); 
			},
			myUploader() {
				this.$store.commit('Loading');
				const Consulta = new API('Documentos');
				Consulta.Procesar('Legales',{
					caso: 	'Foto',
					foto: 'docleg'+this.product.id,
				}).then(response => {
					this.product.img = response.result;
					this.$store.commit('Loading');
				});
			},
			Ver(index){
				window.open(url.upload+'docleg'+index+'.pdf', '_blank');
			},
			Buscar() {
				if(this.empresa){
					this.products = [];
					this.$store.commit('Loading');
					const Consulta = new API('Documentos');          
					Consulta.Procesar('Legales',{
						caso: 	'Buscar',
						empresa: 	this.empresa.code
					}).then(response => {
						//this.$store.state.error = response;
						this.products = response.result.documentos;
						this.$store.commit('Loading');
					});
				}
			},
			openNew() {
				this.nuevo = true;
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.tipo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Selecciones el Tipo de Documento', life: 5000});
				} else if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha', life: 5000});
				} else if (this.product.vencimiento == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Modalidad de Vencimiento', life: 5000});
				} else {
                    this.$store.commit('Loading');
					this.productDialog = false;
					const Consulta = new API('Documentos');          
					if (this.nuevo) {
						Consulta.Procesar('Legales',{
							caso: 	'INS',
							empresa: this.empresa.code,
							tipo: this.product.tipo.code,
							fecha: this.product.fecha,
							vencimiento: this.product.vencimiento.code,
							activo: this.product.activo,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
                            this.$store.commit('Loading');
						});
					} else {
						Consulta.Procesar('Legales',{
							caso: 	'UPD',
							id: 	this.product.id,
							tipo: this.product.tipo.code,
							fecha: this.product.fecha,
							vencimiento: this.product.vencimiento.code,
							activo: this.product.activo,
							img: this.product.img,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
                            this.$store.commit('Loading');
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.nuevo = false;
				this.product = {...product};
				this.tipos.forEach(element => {
					if(this.product.tipo_id==element.code){
						this.product.tipo = element;
					}
				});  
				this.vencimientos.forEach(element => {
					if(this.product.vencimiento_id==element.code){
						this.product.vencimiento = element;
					}
				});  
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
                const Consulta = new API('Documentos');          
				Consulta.Procesar('Legales',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
