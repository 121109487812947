import API from './API';

class APIs {

    constructor(modulo=null) {
        this.modulo = modulo;
        this.token = localStorage.getItem('Token');
    }
    Estatus() {
        return API({
            modulo:  this.modulo
        }).then(res => res.data);
    }
    Inf(funcion) {
        return API({
            modulo:  this.modulo,
            funcion:  funcion
        }).then(res => res.data);
	}
    Ini(funcion) {
        return API({
            modulo:  this.modulo,
            funcion:  funcion,
            token:  this.token
        }).then(res => res.data);
	}
    Procesar(funcion,array) {
        return API({
            modulo:  this.modulo,
            funcion:  funcion,
            token:  this.token,
            array:  array
        }).then(res => res.data);
    }
}

export default APIs;