import { createStore } from 'vuex';
import url from "../service/_URL";
import API from "../service/ApiConsulta";
import router from '../router.js';

export default createStore({
  state: {
    loading: false,
    authenticated: false,
    valido: false,
    url: null,
    correo: null,
    usuario: null,
    nombre: null,
    apellido: null,
    nucleo: null,
    rol: 0,
    verificacion: false,
    estatus: false,
    mobile: false,
    error: null,
    admin: false,
    paginas: [],
    menu : [
    //  {label: 'Empresa', icon: 'bi bi-globe', to: '/empresa'},
    //  {label: 'Servicios', icon: 'bi bi-shield-check', to: '/servicios'},
    //  {label: 'Forma de Pago', icon: 'bi bi-credit-card', to: '/formapago'},
    //  {label: 'Atención al Cliente', icon: 'bi bi-whatsapp', to: '/atencion'},
    ],
    menuser: [],
    menuadmin: [],
    menu_ini: [],
    seleccion: {
      caso: null,
      id: 0
    },
    resumen: {},
  },
  mutations: {
    Loading(){
      this.state.loading = !this.state.loading;	
    },
    Estatus(){
      this.state.url = url.doc;	
      const Consulta = new API();
      Consulta.Estatus().then(response => {
        this.state.estatus = response.estatus;	
      });
    },
    Mobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        && screen.width<1024) {
        this.state.mobile = true;
      } else {
        this.state.mobile = false;
      }    
    },
    Login(state,valores){
      this.state.authenticated = true;	
      localStorage.setItem('Token', valores.token);
      this.state.usuario = valores.nombre+' '+valores.apellido;   
      this.state.nombre = valores.nombre;        
      this.state.apellido = valores.apellido;   
      this.state.nucleo = valores.nucleo;
      this.state.correo = valores.correo;
      this.state.valido = valores.valido;
      this.state.menu_ini = this.state.menu;
      this.state.menu = valores.menu;
      this.state.admin = valores.admin;

      if (valores.admin) {
        this.state.menuadmin = valores.menuadmin;
        this.state.menuadmin.forEach(element => {
            this.state.paginas.push(element.to);
        });  
      }
      this.state.menu.forEach(element => {
        element.items.forEach(element => {
          this.state.paginas.push(element.to);
        }); 
      });  
    },
    Validar(state,pagina){
      this.state.insert = false;
      this.state.edit = false;
      this.state.delete = false;
      if(!this.state.paginas.includes(pagina.path)){
        router.push({ path: '/' });
      } 
    },
    Seleccion(state,valores){
      this.state.seleccion.caso = valores.caso;
      this.state.seleccion.id = valores.id;
    },
    Logout(){
      const Consulta = new API('Seguridad');
      Consulta.Ini('Exit');
      localStorage.removeItem('Token');
      this.state.authenticated = false;
      this.state.usuario = null;   
      this.state.correo = null;
      this.state.valido = false;
      this.state.admin = false;
      this.state.paginas = [];
      this.state.menu = this.state.menu_ini;
      this.state.menuadmin = [];
    }
  },
  actions: {
  },
  modules: {
  }
})
