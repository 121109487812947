import jsXLS from "js-export-excel";

class XLSjs {

    moneda(number){
        return new Intl.NumberFormat("de-DE").format(parseFloat(number).toFixed(2));
    }

    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }

    Personal(titulo,data) {
        var option = {};
        option.fileName = titulo.nombre;
        var body = [];
        var activo = 'Activo';

        for (var i = 0; i < data.length; i++) {
            if(data[i].activo){activo = 'Activo';} else {
                activo=this.convfech(data[i].fechaegre);
            }
			body.push({
                C1: i+1,
                C2: data[i].empresa,
                C3: data[i].nac+'-'+(new Intl.NumberFormat("de-DE").format(data[i].cedula)),
                C4: data[i].apellidos,
                C5: data[i].nombres,
                C6: data[i].cargo,
                C7: this.convfech(data[i].fechaing),
                C8: activo,
                C9: data[i].sueldo+'$',
                C10: data[i].bono+'$',
                C11: (parseFloat(data[i].sueldo)+parseFloat(data[i].bono))+'$',
                C12: null,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12"],
            sheetHeader: ['Nº','Empresa','Cédula','Apellidos','Nombres','Cargo','Fecha de Ingreso','Fecha de Egreso','Sueldo','Bono', 'Total' ,'Observaciones'],
            columnWidths: [2,15,5,10,10,10,5,5,5,5,7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();

        

    }


    Personal2(titulo,data) {
        var option = {};
        option.fileName = titulo.nombre;
        var body = [];
        var activo = 'Activo';

        for (var i = 0; i < data.length; i++) {
            if(data[i].fechaegre){activo=this.convfech(data[i].fechaegre);}
			body.push({
                C1: i+1,
                C2: data[i].empresa,
                C3: data[i].nac+'-'+(new Intl.NumberFormat("de-DE").format(data[i].cedula)),
                C4: data[i].apellidos,
                C5: data[i].nombres,
                C6: this.convfech(data[i].fechanac),
                C7: data[i].edad,
                C8: data[i].telefono,
                C9: data[i].correo,
                C10: this.convfech(data[i].fechaing),
                C11: activo,
                C12: data[i].antig_year+' años '+data[i].antig_month+' meses '+data[i].antig_day+' días',
                C13: data[i].sueldo+'$',
                C14: data[i].bono+'$',
                C15: parseFloat(data[i].sueldo+data[i].bono)+'$',
                C16: null,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16"],
            sheetHeader: ['Nº','Empresa','Cédula','Apellidos','Nombres','Fecha Nacimiento','Edad','Celular','Correo','Fecha de Ingreso','Fecha de Egreso','Tiempo de Servicio','Sueldo','Bono', 'Total' ,'Observaciones'],
            columnWidths: [2,25,5,10,10,7,2,7,15,7,7,10,10,10,10,20],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();

        

    }







}


export default XLSjs;



//export default exports;