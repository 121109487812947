<template>
	<div class="p-grid">

		<div class="p-col-12">
			<div class="card">
				<FullCalendar :events="events" :options="options" v-if="mostrar"/>

				<Dialog v-model:visible="eventDialog" :style="{width: '450px'}" header="Event Details" :modal="true" :closable="true">
					<div class="p-fluid">
						<div class="p-field"  v-if="clickedEvent">
							<br>
							{{changedEvent.title}}							
						</div>
						<div class="p-field"  v-if="clickedEvent">
							<br>
							{{changedEvent.empresa}}							
						</div>
						<!--
						<div class="p-field">
							<label for="start">From</label>
							<Calendar id="start" v-if="clickedEvent" v-model="changedEvent.start" :showTime="true" appendTo="body"/>
						</div>
						<div class="p-field">
							<label for="end">To</label>
							<Calendar id="end" v-if="clickedEvent" v-model="changedEvent.end" :showTime="true" appendTo="body"/>
						</div>
						<div class="p-field-checkbox">
							<Checkbox id="allday" name="allday" value="All Day" v-model="changedEvent.allDay" />
							<label for="allday">All Day</label>
						</div>
						-->
					</div>
					<template #footer>
						<!--
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="save"/>
						<Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reset" />
						-->
					</template>
				</Dialog>

			</div>

		</div>

		
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import PDF from "../service/PDF";
	import url from "../service/_URL";
	import XLS from "../service/XLS";

	import dayGridPlugin from '@fullcalendar/daygrid';
	import timeGridPlugin from '@fullcalendar/timegrid';
	import interactionPlugin from '@fullcalendar/interaction';

	export default {
		data() {
			return {
				mostrar: false,
				eventDialog: false,
				clickedEvent: null,
				changedEvent: {title:'', start: null, end:'', allDay: null, empresa: null},
				options: {
					plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
					defaultDate: '2019-01-01',
					header: {
						left: 'prev,next',
						center: 'title',
						right: 'dayGridMonth,timeGridWeek,timeGridDay'
					},
					editable: true,
					eventClick: (e) => {
						this.eventDialog = true;

						this.clickedEvent = e.event;

						this.changedEvent.title = this.clickedEvent.title;
						this.changedEvent.start = this.clickedEvent.start;
						this.changedEvent.end = this.clickedEvent.end;
						this.changedEvent.empresa = this.clickedEvent.empresa;
					}
				},
				events: [
					{
						"id": 1,
						"title": "All Day Event",
						"start": "2022-01-15"
					},
					
				],



			}
		},
		productService: null,
		created() {
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				const Consulta = new API('RRHH');
				Consulta.Ini('Cumples').then(result => {
					//this.$store.state.error = result.consult;
					this.options.defaultDate = result.consult.inicia;

					this.events = result.consult.personal;
					this.mostrar = true;
					this.$store.commit('Loading');
				}); 
			},
			VERPDF(data){
				const doc = new PDF();
				doc.Personal(this.caso,data);
			},
			VERXLS(){
				const xls = new XLS();
				xls.Personal(this.caso,this.products);
			},
			VerCarnet(data){
				window.open(url.api+'Carnet.php?&id='+data.cedula, '_blank');
			},

			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},

			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
