 <template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
					</template>
					<template v-slot:right>
					</template>			
				</Toolbar>
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Registro de Viáticos</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="estatus" header="Estatus">
						<template #body="slotProps">
							<Avatar icon="bi bi-patch-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-if="slotProps.data.estatus==1"/>
							<Avatar icon="bi bi-patch-plus" class="p-mr-2" size="large" style="background-color:blue; color: #ffffff" v-if="slotProps.data.estatus==2"/>
							<Avatar icon="bi bi-patch-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="slotProps.data.estatus==3"/>
							<Avatar icon="bi bi-patch-minus" class="p-mr-2" size="large" style="background-color:red; color: #ffffff" v-if="slotProps.data.estatus==4"/>
							Nº {{slotProps.data.id}}						
							<br><strong>{{slotProps.data.etapa}}</strong>
						</template>
					</Column>
					<Column field="fecha_ida" header="Fecha" :sortable="true">
						<template #body="slotProps">
							IDA: {{convfech(slotProps.data.fecha_ida)}}<br>
							<div v-if="slotProps.data.idavuelta">Regreso: {{convfech(slotProps.data.fecha_regreso)}}</div>
						</template>
					</Column>
					<Column field="salida" header="Origen" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.salida}}
						</template>
					</Column>
					<Column field="destino" header="Destino" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.destino}}
						</template>
					</Column>
					<Column field="empresa" header="Empresa" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.empresa}}
						</template>
					</Column>
					<Column field="costo" header="Costo" :sortable="true">
						<template #body="slotProps">
							${{formatCurrency(parseFloat(slotProps.data.costo).toFixed(2))}} 
						</template>
					</Column>
					<Column field="recurso" header="Recurso" :sortable="true">
						<template #body="slotProps">
							<span class="p-tag p-tag-info" v-if="slotProps.data.estatus==1">En Pausa</span>
							<span class="p-tag p-tag-success" v-if="slotProps.data.recurso && slotProps.data.estatus>1">Pagado</span>
							<span class="p-tag p-tag-danger" v-if="!slotProps.data.recurso && slotProps.data.estatus>1">En Proceso</span><br>
							<strong v-if="slotProps.data.recurso && slotProps.data.formapago>0">{{slotProps.data.nformapago}}</strong>
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit & slotProps.data.estatus==1"/>
							<Button icon="pi pi-eye" class="p-button-rounded p-button-warning p-mr-2" @click="editProduct(slotProps.data)" v-if="edit & slotProps.data.estatus!=1"/>
							<!--<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del"/>-->
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Registro de Viático" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-4">
								<label for="ruta">Ruta</label>
								<Dropdown id="state" v-model="product.ruta" :options="rutas" optionLabel="nombre" :disabled="product.estatus != 1 && product.estatus" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label for="fecha">Fecha de Salida</label>
                                <InputText v-model="product.fecha_ida" :disabled="product.estatus != 1 && product.estatus" type="date"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Ida y Vuelta</h6>
								<InputSwitch v-model="product.idavuelta" :disabled="product.estatus != 1 && product.estatus"  v-bind="Calculo1()"/>
							</div>
							<div class="p-col-3">
								<label for="fecha" v-if="product.idavuelta">Fecha de Regreso</label>
                                <InputText v-model="product.fecha_regreso" type="date" v-if="product.idavuelta && product.fecha_ida" :min="product.fecha_ida" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
							<div class="p-col-6">
								<label for="vehiculo">Medio de Transporte</label>
								<Dropdown id="state" v-model="product.vehiculo" :options="vehiculos" optionLabel="nombre" placeholder="Selecciona..." :disabled="product.estatus != 1 && product.estatus"></Dropdown>
							</div>
							<div class="p-col-6">
								<label class="p-mb-3">Empresa</label>
								<Dropdown id="state" v-model="product.empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..." :disabled="product.estatus != 1 && product.estatus"></Dropdown>
							</div>
							<div class="p-col-2">
								<label for="fecha">Nº Días</label>
								<InputNumber id="minmax-buttons" v-model="product.dias" mode="decimal" showButtons :min="0" :max="100" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Nº Personas</label>
								<InputNumber id="minmax-buttons" v-model="product.personas" mode="decimal" showButtons :min="1" :max="100" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Nº Desayunos</label>
								<InputNumber id="minmax-buttons" v-model="product.cant_des" mode="decimal" showButtons :min="0" :max="100" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Nº Almuerzos</label>
								<InputNumber id="minmax-buttons" v-model="product.cant_alm" mode="decimal" showButtons :min="0" :max="100" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Nº Cenas</label>
								<InputNumber id="minmax-buttons" v-model="product.cant_cen" mode="decimal" showButtons :min="0" :max="100" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
							<div class="p-col-8">
								<label for="fecha">Motivo</label>
                                <InputText v-model="product.motivo" type="text" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
							<div class="p-col-4">
								<label for="vehiculo">Beneficiario(a)</label>
								<Dropdown id="state" v-model="product.responsable" :options="personal" optionLabel="nombre" placeholder="Selecciona..." :disabled="product.estatus != 1 && product.estatus"></Dropdown>
							</div>
							<div class="p-col-1">
								<label for="fecha">Transporte $</label>	
								<InputNumber id="currency-us" v-model="product.transporte" disabled mode="currency" currency="USD" locale="en-US" v-bind="Calculo1()"/>
							</div>
							<div class="p-col-1">
								<label for="fecha">Peaje $</label>	
								<InputNumber id="currency-us" v-model="product.peaje" disabled mode="currency" currency="USD" locale="en-US" v-bind="Calculo1()"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Alojamiento $</label>	
								<InputNumber id="currency-us" v-model="product.alojamiento" disabled mode="currency" currency="USD" locale="en-US" v-bind="Calculo2()"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Desayunos $</label>	
								<InputNumber id="currency-us" v-model="product.desayuno" disabled mode="currency" currency="USD" locale="en-US" v-bind="Calculo3()"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Almuerzos $</label>	
								<InputNumber id="currency-us" v-model="product.almuerzo" disabled mode="currency" currency="USD" locale="en-US" v-bind="Calculo4()"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Cenas $</label>	
								<InputNumber id="currency-us" v-model="product.cena" disabled mode="currency" currency="USD" locale="en-US" v-bind="Calculo5()"/>
							</div>
							<div class="p-col-2">
								<label for="fecha">Extra $</label>	
								<InputNumber id="currency-us" v-model="product.extra" mode="currency" currency="USD" locale="en-US" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
							<div class="p-col-12">
								<label for="fecha">Observación</label>
                                <InputText v-model="product.observacion" type="text" :disabled="product.estatus != 1 && product.estatus"/>
							</div>
						</div>
					</div>
					<template #footer>
						<h4>Total: ${{formatCurrency(((parseFloat(product.transporte)+parseFloat(product.peaje)+parseFloat(product.alojamiento)+parseFloat(product.desayuno)+parseFloat(product.almuerzo)+parseFloat(product.cena)+parseFloat(product.extra))).toFixed(2))}}</h4>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import url from "../service/_URL";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				products: [],
				procesos: [],
				personal: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				codes: [
					{code: 'V', nombre: 'V'},
					{code: 'J', nombre: 'J'},
					{code: 'G', nombre: 'G'}
				],
                empresas: [],
                rutas: [],
				vehiculos: [],
			}
		},
		productService: null,
		created() {
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				const Consulta = new API('Viaticos');
				Consulta.Ini('Viatico').then(result => {
					//this.$store.state.error = result.consult.rutas;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.rutas = result.consult.rutas;
					this.empresas = result.consult.empresas;
					this.products = result.consult.viaticos;
					this.personal = result.consult.personal;
					this.vehiculos = result.consult.vehiculos;
					this.$store.commit('Loading');
				}); 
			},
			openNew() {
				this.nuevo = true;
				this.product = {};
				this.procesos = [];
				this.product.ruta = this.rutas[0];
				this.product.vehiculo = this.vehiculos[0];
				this.product.fecha_ida = null;
				this.product.fecha_regreso = null;
				this.product.empresa = this.empresas[0];
				this.product.dias = 0;
				this.product.personas = 1;
				this.product.cant_des = 0;
				this.product.cant_alm = 0;
				this.product.cant_cen = 0;
				this.product.extra = 0;
				this.product.peaje = 0;
				this.product.transporte = 0;
				this.product.obervacion = 'Ninguna';
				this.submitted = false;
				this.productDialog = true;
			},
			Validar() {
				this.$store.commit('Loading');
				this.procesos = [];
				const Consulta = new API('Viaticos');
				Consulta.Procesar('Viatico',{
					caso: 	'validar',
					empresa: this.product.empresa.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.procesos = response.result;
					}
					this.$store.commit('Loading');
				});
			},
			Calculo1(){
				var vuelta = 1;
				if(this.product.idavuelta){vuelta = 2;}
				if (this.product.vehiculo.code==1){
					this.product.transporte = vuelta*this.product.ruta.valor_vehemp;
				}
				if (this.product.vehiculo.code==2){
					this.product.transporte = vuelta*this.product.ruta.valor_vehemp/2;
				}
				if (this.product.vehiculo.code==3){
					this.product.transporte = vuelta*this.product.personas*this.product.ruta.valor_terrestre;
				}
				if (this.product.vehiculo.code==4){
					this.product.transporte = vuelta*this.product.ruta.valor_particular;
				}
				if (this.product.vehiculo.code==5){
					this.product.transporte = vuelta*this.product.personas*this.product.ruta.valor_aereo;
				}
				if (this.product.vehiculo.code>0){
					this.product.peaje = vuelta*this.product.ruta.numpeajes*this.product.vehiculo.montopeaje;
				}
			},
			Calculo2(){
				this.product.alojamiento = this.product.personas*this.product.dias*this.product.ruta.valor_alojamiento;
			},
			Calculo3(){
				this.product.desayuno = this.product.personas*this.product.cant_des*this.product.ruta.valor_comida1;
			},
			Calculo4(){
				this.product.almuerzo = this.product.personas*this.product.cant_alm*this.product.ruta.valor_comida2;
			},
			Calculo5(){
				this.product.cena = this.product.personas*this.product.cant_cen*this.product.ruta.valor_comida3;
			},
			Ver(index){
				window.open(url.upload+'docleg'+index+'.pdf', '_blank');
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.ruta == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Ruta', life: 5000});
				} else if (this.product.fecha_ida == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha de Ida', life: 5000});
				} else if (this.product.fecha_regreso == null && this.product.idavuelta) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha de Regreso', life: 5000});
				} else if (this.product.empresa == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Empresa', life: 5000});
				//} else if (this.product.proceso == null) {
				//	this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Proceso', life: 5000});
				} else if (this.product.motivo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Motivo', life: 5000});
				} else if (this.product.responsable == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Responsable', life: 5000});
				} else {
                    this.$store.commit('Loading');
					this.productDialog = false;
					const Consulta = new API('Viaticos');          
					if (this.nuevo) {
						Consulta.Procesar('Viatico',{
							caso: 	'INS',
							fecha_ida: this.product.fecha_ida,
							fecha_regreso: this.product.fecha_regreso,
							ruta: this.product.ruta.code,
							idavuelta: this.product.idavuelta,
							dias: this.product.dias,
							motivo: this.product.motivo,
							transporte: this.product.transporte,
							alojamiento: this.product.alojamiento,
							desayuno: this.product.desayuno,
							almuerzo: this.product.almuerzo,
							peaje: this.product.peaje,
							cena: this.product.cena,
							cant_des: this.product.cant_des,
							cant_alm: this.product.cant_alm,
							cant_cen: this.product.cant_cen,
							empresa: this.product.empresa.code,
							vehiculo: this.product.vehiculo.code,
							personas: this.product.personas,
							extra: this.product.extra,
							observacion: this.product.observacion,
							//proceso: this.product.proceso.code,
							responsable: this.product.responsable.code,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
                            this.$store.commit('Loading');
						});
					} else {
						Consulta.Procesar('Viatico',{
							caso: 	'UPD',
							id: 	this.product.id,
							fecha_ida: this.product.fecha_ida,
							fecha_regreso: this.product.fecha_regreso,
							ruta: this.product.ruta.code,
							idavuelta: this.product.idavuelta,
							dias: this.product.dias,
							motivo: this.product.motivo,
							transporte: this.product.transporte,
							alojamiento: this.product.alojamiento,
							desayuno: this.product.desayuno,
							almuerzo: this.product.almuerzo,
							peaje: this.product.peaje,
							cena: this.product.cena,
							cant_des: this.product.cant_des,
							cant_alm: this.product.cant_alm,
							cant_cen: this.product.cant_cen,
							empresa: this.product.empresa.code,
							vehiculo: this.product.vehiculo.code,
							personas: this.product.personas,
							extra: this.product.extra,
							observacion: this.product.observacion,
							//proceso: this.product.proceso.code,
							responsable: this.product.responsable.code,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
                            this.$store.commit('Loading');
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.$store.commit('Loading');
				this.nuevo = false;
				this.product = {...product};
				this.rutas.forEach(element => {
					if(this.product.ruta_id==element.code){
						this.product.ruta = element;
					}
				});  
				this.vehiculos.forEach(element => {
					if(this.product.vehiculo_id==element.code){
						this.product.vehiculo = element;
					}
				});  
				this.empresas.forEach(element => {
					if(this.product.empresa_id==element.code){
						this.product.empresa = element;
					}
				});
				this.personal.forEach(element => {
					if(this.product.responsable==element.code){
						this.product.responsable = element;
					}
				});
				const Consulta = new API('Viaticos');
				Consulta.Procesar('Viatico',{
					caso: 	'validar',
					empresa: this.product.empresa.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.procesos = response.result;
						this.procesos.forEach(element => {
							if(this.product.proceso==element.code){
								this.product.proceso = element;
							}
						});
					}
					this.$store.commit('Loading');
				});
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
                const Consulta = new API('Viaticos');          
				Consulta.Procesar('Viatico',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},
			formatCurrency(value) {
				if(value)
					return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
				return;
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
