<template>
	<div class="p-grid" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
						<!--<Button :label="Borrar" icon="pi pi-trash" class="p-button-danger p-mb-2" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />-->
					</template>
					
					<template v-slot:right>
						<!--
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-mb-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help p-mb-2" @click="exportCSV($event)"  />
						-->
					</template>
				</Toolbar>
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Roles</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="ID" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="paginas" header="Páginas" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.pag}}
						</template>
					</Column>
					<Column field="usuarios" header="Usuarios" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.usuarios}}
						</template>
					</Column>
					<Column field="activo" header="Estatus" :sortable="true">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-unlock" class="p-button-rounded p-button-warning p-mr-2" @click="editPermiso(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Rol" :modal="true" class="p-fluid">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-6">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true" autofocus/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Estatus</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-col-12" v-if="product.id">
									<h5>Páginas</h5>
									<PickList v-model="product.paginas" dataKey="code">
										<template #sourceHeader>
											Disponibles
										</template>
										<template #targetHeader>
											Activos
										</template>
										<template #item="slotProps">
											<div>{{slotProps.item.nombre}}</div>
										</template>
									</PickList>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="permisosDialog" :style="{width: size}" header="Permisología" :modal="true" class="p-fluid">
					<Toast/>
					<br>
                    <div class="p-field">
						<h5>Rol: {{permisos.nombre}}</h5>
						<div class="p-grid">
									<div class="p-col-4"><strong>Módulo</strong></div>
									<div class="p-col-5"><strong>Página</strong></div>
									<div class="p-col-1"><strong>Editar</strong></div>
									<div class="p-col-1"><strong>Insertar</strong></div>
									<div class="p-col-1"><strong>Borrar</strong></div>
							<div class="p-col-12"  v-for="(stats, index) in permisos.permisologia" :key="index">
								<div class="p-grid">
									<div class="p-col-4">
										{{stats.modulo}}
									</div>
									<div class="p-col-5">
										{{stats.pagina}}
									</div>
									<div class="p-col-1">
										<InputSwitch :id="index" v-model="permisos.permisologia[index].edit" />
									</div>
									<div class="p-col-1">
										<InputSwitch :id="index" v-model="permisos.permisologia[index].insert" />
									</div>
									<div class="p-col-1">
										<InputSwitch :id="index" v-model="permisos.permisologia[index].delete" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="savePermiso()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="permisosDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				permisosDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				permisos: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				niveles: null,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.permisos = null;
				this.products = null;
				this.$store.commit('Loading');
				const Consulta = new API('Configuracion');
				Consulta.Ini('Roles').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult;
					this.$store.commit('Loading');
				}); 
			},
			openNew() {
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Nombre de Rol', life: 5000});
				} else {			
					this.productDialog = false;
					const Consulta = new API('Configuracion');          
					if (this.product.id) {
						Consulta.Procesar('Roles',{
							caso: 	'UPD',
							id: 	this.product.id,
							nombre: this.product.nombre,
							activo: this.product.activo,
							paginas: this.product.paginas
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
						Consulta.Procesar('Roles',{
							caso: 	'INS',
							id: 	this.product.id,
							nombre: this.product.nombre,
							activo: this.product.activo
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			savePermiso() {
				this.permisosDialog = false;
                const Consulta = new API('Configuracion');          
					Consulta.Procesar('Roles',{
						caso: 	'UPD2',
						id: 	this.permisos.id,
						paginas: this.permisos.permisologia
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
					});
				this.permisosDialog = false;
				this.permisos = {};
			},
			editProduct(product) {
				this.product = {...product};
				this.productDialog = true;
			},
			editPermiso(permiso) {
				this.permisos = {...permiso};
				this.permisosDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
                const Consulta = new API('Configuracion');          
				Consulta.Procesar('Roles',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.Mostrar();
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
