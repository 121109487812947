<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>

					</template>
					
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
						<!--
						<Button label="Export" icon="pi pi-upload" class="p-button-help p-mb-2" @click="exportCSV($event)"  />
						
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-mb-2 p-d-inline-block" />
							-->
					</template>
				
				</Toolbar>
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Registro de Clientes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="RIF" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.code}}-{{slotProps.data.rif}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre del Cliente" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Teléfonos" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.tlflocal}}<br>
							{{slotProps.data.celular}}
						</template>
					</Column>
					<Column field="correo" header="Correo" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.correo}}
						</template>
					</Column>
					<Column field="correo" header="Tipo" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.tiponombre}}
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Cliente" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-1">
								<label for="name">RIF</label>
								<Dropdown v-model="product.code" :options="codes" optionLabel="nombre" placeholder="..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label for="name">-</label>
								<InputNumber v-model="product.rif" integeronly required="true" min="1"/>
							</div>
							<div class="p-col-5">
								<label for="name">Nombre del Cliente</label>
								<InputText id="name" v-model.trim="product.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Tipo de Cliente</label>
								<Dropdown id="state" v-model="product.tipo" :options="tipos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-6">
								<label for="name">Correo</label>
								<InputText id="name" v-model.trim="product.correo" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-3">
								<label>Telf Local</label>
								<InputMask v-model="product.tlflocal" mask="(9999) 999-9999" />
							</div>                      
							<div class="p-col-3">
								<label>Telf Celular</label>
								<InputMask v-model="product.celular" mask="(9999) 999-9999" />
							</div>
							<div class="p-col-6">
								<label for="name">Instagram</label>
								<InputText id="name" v-model.trim="product.instagram"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Contribuyente</h6>
								<InputSwitch v-model="product.contribuyente" />
							</div>
						</div>
						<h5>Contacto</h5>			
						<div class="p-fluid p-formgrid p-grid">        
							<div class="p-col-5">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-3">
								<label>Telf Celular</label>
								<InputMask v-model="product.celular" mask="(9999) 999-9999" />
							</div>

							<div class="p-col-6">
								<label for="name">Correo</label>
								<InputText id="name" v-model.trim="product.correo" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>

						</div>	
						<h5>Dirección</h5>			
						<div class="p-fluid p-formgrid p-grid">        
							<div class="p-col-4">
								<label>Estado</label>
								<Dropdown v-model="product.estado" :options="estados" optionLabel="nombre" placeholder="Selecciona..." @change="Validacion()"></Dropdown>
							</div>
							<div class="p-col-4">
								<label>Ciudad</label>
								<Dropdown v-model="product.ciudad" :options="ciudades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label>Municipio</label>
								<Dropdown v-model="product.municipio" :options="municipios" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-10">
								<label>Dirección Detallada</label>
								<Textarea v-model="product.direccion"  rows="2"/>						
							</div>
							<div class="p-col-2">
								<label for="name">Código Postal</label>
								<InputMask v-model="product.postal" mask="9999" />
							</div>
						</div>	
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				tipos: null,
				codes: [
					{code: 'V', nombre: 'V'},
					{code: 'J', nombre: 'J'},
					{code: 'G', nombre: 'G'}
				],
				estados: null,
				ciudades: [],
				municipios: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				const Consulta = new API('Proyectos');
				Consulta.Ini('Clientes').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.tipos = result.consult.tipos;
					this.products = result.consult.clientes;
					this.estados = result.consult.estados;
					this.$store.commit('Loading');
				}); 
			},
			Validacion() {
				this.$store.commit('Loading');
				this.parroquias = [];
				const Consulta = new API('Proyectos');
				Consulta.Procesar('Clientes',{
					caso: 	'Validacion',
					estado: this.product.estado.code,
				}).then(response => {
					//this.$store.state.error = response;	
					this.ciudades = response.result.ciudades;
					this.municipios = response.result.municipios;
					this.ciudades.forEach(element => {
						if(this.product.ciudad==element.code){
							this.product.ciudad = element;
						}
					});
					this.municipios.forEach(element => {
						if(this.product.municipio==element.code){
							this.product.municipio = element;
						}
					});
					this.$store.commit('Loading');
				});
			},
			openNew() {
				this.nuevo = true;
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.code == null || this.product.rif == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Rif', life: 5000});
				} else if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.tipo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Tipo de Cliente', life: 5000});
				} else if (this.product.correo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Correo', life: 5000});
				} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
					test(this.product.correo)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
				} else if (this.product.tlflocal == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Tlf Local', life: 5000});
				} else if (this.product.celular == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Tlf Celular', life: 5000});
				} else if (this.product.estado == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Estado', life: 5000});
				} else if (this.product.ciudad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Ciudad', life: 5000});
				} else if (this.product.municipio == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Municipio', life: 5000});
				} else if (this.product.direccion == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Dirección', life: 5000});
				} else if (this.product.postal == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Código Postal', life: 5000});
				} else {
					this.$store.commit('Loading');
					this.productDialog = false;
					const Consulta = new API('Proyectos');          
					if (this.nuevo) {
						Consulta.Procesar('Clientes',{
							caso: 	'INS',
							code: this.product.code.code,
							rif: this.product.rif,
							nombre: this.product.nombre,
							correo: this.product.correo,
							tipo: this.product.tipo.code,
							contribuyente: this.product.contribuyente,
							tlflocal: this.product.tlflocal,
							celular: this.product.celular,
							estado: this.product.estado.code,
							ciudad: this.product.ciudad.code,
							municipio: this.product.municipio.code,
							direccion: this.product.direccion,
							postal: this.product.postal,
							instagram: this.product.instagram
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
							this.$store.commit('Loading');
						});
					} else {
						Consulta.Procesar('Clientes',{
							caso: 	'UPD',
							id: 	this.product.id,
							code: this.product.code.code,
							rif: this.product.rif,
							nombre: this.product.nombre,
							correo: this.product.correo,
							tipo: this.product.tipo.code,
							contribuyente: this.product.contribuyente,
							tlflocal: this.product.tlflocal,
							celular: this.product.celular,
							estado: this.product.estado.code,
							ciudad: this.product.ciudad.code,
							municipio: this.product.municipio.code,
							direccion: this.product.direccion,
							postal: this.product.postal,
							instagram: this.product.instagram
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
							this.$store.commit('Loading');
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.nuevo = false;
				this.product = {...product};
				this.codes.forEach(element => {
					if(this.product.code==element.code){
						this.product.code = element;
					}
				});  
				this.tipos.forEach(element => {
					if(this.product.tipo==element.code){
						this.product.tipo = element;
					}
				});  
				this.estados.forEach(element => {
					if(this.product.estado==element.code){
						this.product.estado = element;
					}
				});
				this.Validacion();
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
                const Consulta = new API('Proyectos');          
				Consulta.Procesar('Clientes',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
